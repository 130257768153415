import React from "react"
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import ListOptions from "../../../../common/utils/ListOptions";

let Item = injectIntl(props => {

    const {id, name, site, quantity, collectDate,status, setFormView} = props

    return (
        <tr data-row="0" className="datatable-row text-center" style={{left: "0px"}}>

            <td className="datatable-cell">
                <span style={{width: "350px"}} className="text-uppercase text-left font-weight-bold">
                    <FormattedMessage id={`app.label.${name.toLowerCase()}`}/>
                </span>
            </td>

            <td className="datatable-cell">
                <span style={{width: "100px"}}>{collectDate || ''}</span>
            </td>
            <td className="datatable-cell">
                <span style={{width: "100px"}}>{quantity || ''} </span>
            </td>

            <td className="datatable-cell">
                <span style={{width: "100px"}}>{site || ''} </span>
            </td>

            <ListOptions {...{id, viewAction: setFormView, entities: 'categories'}}/>
        </tr>

    )
})
export default React.memo(withRouter(Item))
