import * as React from "react";
import {useRef} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useReactToPrint} from 'react-to-print';
import {withRouter} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {getIntlMsg} from "../../../../../../../constants/utils";
import RequestForm from "../../../reports/traveler/RequestForm";
import RequestNotice from "../../../reports/traveler/RequestNotice";


const CartBtn = injectIntl(props => {

    const {intl, item, handleStateChange} = props;
    const {id} = item
    const requestFormRef = useRef();
    const receiptRef = useRef();

    const handlePrintRequestForm = useReactToPrint({
        content: () => requestFormRef.current,
    });


    return (

        <div className="btn-group  btn-group-sm" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-light-primary" onClick={handlePrintRequestForm}>
                <FormattedMessage id={`app.label.print_form`}/>
            </button>
            {item?.testType?.name === 'PCR'

                ? <button type="button" className="btn btn-light-primary"
                          data-toggle="modal" data-target={`#notification${id}`}
                          title={getIntlMsg(intl, `app.label.send_to_notification`)}>
                    <FormattedMessage id={`app.label.send_to_notification`}/>
                </button>
                :
                <button type="button" className="btn btn-light-primary"
                        data-toggle="modal" data-target={`#specimens${id}`}
                        title={getIntlMsg(intl, `app.label.send_to_specimens`)}>
                    <FormattedMessage id={`app.label.send_to_specimens`}/>
                </button>

            }


            {item?.testType?.name === 'PCR'
                ? <ChangeStateModal {...{id, title: 'notification', handleStateChange}}/>
                : <ChangeStateModal {...{id, title: 'specimens', handleStateChange}}/>
            }

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <RequestForm ref={requestFormRef} {...{item}}/>
            </div>

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <RequestNotice ref={receiptRef} {...{item}}/>
            </div>
        </div>

    );
});


export default React.memo(withRouter(CartBtn))
