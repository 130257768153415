import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";


const EmptyCallout = injectIntl((props) => {

    const {intl} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    return (
		<div className="card card-custom mb-2 bg-diagonal">
			<div className="card-body">
				<div className="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap">
					<div className="d-flex flex-column mr-5">
						<a href="#" className="h4 text-dark text-hover-primary mb-5">
							<FormattedMessage id={`app.label.${page}_empty`}/>
						</a>
						<p className="text-dark-50">
							<FormattedMessage id={`app.label.${page}_empty_desc`}/>
						</p>
					</div>
					<div className="ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0">
						<a href="#" className="btn font-weight-bolder text-uppercase btn-light-primary py-4 px-6">
							<FormattedMessage id={`app.label.${page}`}/>
						</a>
					</div>
				</div>
			</div>
		</div>

    );
});


export default React.memo(withRouter(EmptyCallout))
