import * as React from "react";
import {useState} from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import StatusDistribution from "./Items/status";
import Spinner from "../../../../../common/utils/Spinner";
import ResultDistribution from "./Items/result";
import MapperDistribution from "./Items/mapper";
import CovidIndicator from "./Items/covidIndicator";
import Filter from './filter'
import ScriptTag from "react-script-tag"
import moment from 'moment'


const LeadershipDashboard = injectIntl(props => {
    const {intl} = props

    useDocumentTitle(getIntlMsg(intl, `app.label.requests_dashboard_desc`));
    const dispatch = useDispatch();
    const criteria = {options: ['leadership-dashboard']}

    const options ={options: ['indicator-dashboard']}
    const param=`?started=${moment(new Date()).format("yyyy-MM-DD")}&ended=${moment(new Date()).format("yyyy-MM-DD")}&name=&site=`

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
        dispatch(actions.loadItems({...options,param}));
    }, [dispatch]);

    const {statusItems,groupByDateCounter, loading,indicator,values} = useSelector(state => ({
        loading: state?.dbReducer?.leadership?.loadingItems,
        statusItems: state?.dbReducer?.leadership?.items?.statusCounter,
        indicator: state?.dbReducer?.indicator?.items,
        groupByDateCounter: state?.dbReducer?.leadership?.items?.groupByDateCounter,
        values: state?.form?.searchForm?.values
    }));



    const statusData = {
        labels: buildChartElement(statusItems,intl)[0],
        datasets: [
            {
                label: '# of Votes',
                data:  buildChartElement(statusItems,intl)[1],
                backgroundColor: ['#3699ff', '#3b5998', '#ff7f26','#f64e60','#1bc5bd','#e4e6ef'],
                borderColor: ['#3699ff', '#3b5998', '#ff7f26','#f64e60','#1bc5bd','#e4e6ef'],
                borderWidth: 1,
            },
        ],
    }

    const [label, setLabel] = useState('request_by')

    const changeDashboard=(label)=>{
        switch (label){
            case 'covid_indicator':
                return <CovidIndicator indicator={indicator} />
            case 'request_by':
                return(
                <React.Fragment>
                    <div className="col-sm-6">
                        {statusData && !loading ? <StatusDistribution {...{statusData}}/> : <Spinner/>}
                    </div>
                    <div className="col-sm-6">
                        {statusData && !loading ? <ResultDistribution {...{statusData}}/> : <Spinner/>}
                    </div>
                    <div className="col-sm-12 mt-5">
                        {groupByDateCounter && !loading ? <MapperDistribution {...{groupByDateCounter}}/> : <Spinner/>}
                    </div>
                </React.Fragment>)
            default:
                return null
        }
    }



    return (
        <div className="d-flex flex-column-fluid">
            <div className="container">
                <div className="row mt-10">

                    <div className="col-sm-12 pb-5">
                        <Filter setLabel={setLabel} label={label} indicator={indicator} values={values}/>
                    </div>
                    {changeDashboard(label)}
                </div>
            </div>
        </div>
    );
});


const buildChartElement = (statusItems, intl) => {

    let labels = [];
    let values = [];
    if (statusItems)
        for (const [key, value] of Object.entries(statusItems)) {
            labels.push(getIntlMsg(intl,`app.label.${key.toLowerCase()}`))
            values.push(value)
        }
    return [labels, values]
}

export default React.memo(LeadershipDashboard)

