import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import Item from "./Item";
import SearchBar from "./SearchBar";
import Spinner from "../../../../common/utils/Spinner";
import TablePager from "../../../common/pagination/TablePager";
import useEntityManager from "../../../../common/hooks/useEntityManager";


const TestApplicantList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView} = props;
    const {module, id} = useParams();


    const criteria = {options: ['requests']};
    const options = {options: ['indicators', 'names']};

    const params = {options: [module]}
    const {} = useEntityManager(params, module)

    const {loading, items} = useSelector(state => ({
        loading: state?.requestReducer?.loadingItems,
        items: state?.requestReducer?.items,
    }));


    console.log(items, '*************')
    return (
        <React.Fragment>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <h5 className="text-black-50 mb-5">
                        <FormattedMessage id="app.label.all_test_indicators"/>
                    </h5>
                    <SearchBar {...{formView, setFormView, options, items}}/>
                    <div
                        className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                        id="kt_datatable_0" style={{}}>
                        <table className="datatable-table" style={{display: "block"}}>
                            <thead className="datatable-head pl-5 pr-5">
                            <tr className="datatable-row" style={{left: "0px"}}>

                                <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.date`}/>
                                </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.lastname`}/>
                                </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.firstname`}/>
                                </span>
                                </th>


                                <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.test_type`}/>
                                </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.result`}/>
                                </span>
                                </th>

                                <th data-autohide-disabled="false"
                                    className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200"}}>
                                     <FormattedMessage id={`app.label.site`}/>
                                </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="datatable-body" style={{}}>
                            {loading ?
                                <tr>
                                    <td>
                                        <Spinner/>
                                    </td>
                                </tr>
                                : [].map((item, i) => <Item {...{...item, setFormView}} key={i}/>)}
                            </tbody>
                        </table>
                        <TablePager/>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
})

export default React.memo(withRouter(TestApplicantList))

