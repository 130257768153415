import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../constants/options/pages";
import GlobalHomePage from "./modules/obsheet/dashboard";
import RequestPageManager from "./modules/request/forms";
import SettingsManager from "./settings";
import Footer from "../layout/footer";
import {GlobalContext} from "../index";
import {useContext} from "react";
import SecurityManager from "./security";
import useSecondaryToggler from "../common/hooks/useScondaryToggler";
import {ObsPageManager} from "./modules/obsheet";
import LeadershipDashboard from "./modules/request/dashboard/leadership";
import SharingManager from "./sharing";
import RequestDashboard from "./modules/request/dashboard/base";

export const PageManager = () => {
    const {module} = useParams()
    useSecondaryToggler(module);

    const {institution} = useContext(GlobalContext);

    return (
        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                {_onLoadPage(module)}
                <Footer {...{institution}}/>
            </div>
        </div>
    )
}



function _onLoadPage(module) {

    switch (module) {
        case pages.REQUEST_MODULE:
            return <RequestPageManager/>
        case pages.OBSERVATION_PAGE:
            return <ObsPageManager/>
        case pages.SETTINGS_MODULE:
            return <SettingsManager/>
        case pages.SECURITY_MODULE:
            return <SecurityManager/>
        case pages.SHARING_MODULE:
            return <SharingManager/>
        case pages.REQUEST_DASHBOARD_PAGE:
            return <RequestDashboard/>
        case pages.FINANCIAL_DASHBOARD_PAGE:
            return <LeadershipDashboard/>
        case pages.LEADERSHIP_DASHBOARD_PAGE:
            return <LeadershipDashboard/>
        case pages.SECURITY_DASHBOARD_PAGE:
            return <LeadershipDashboard/>
        default: {
            return <GlobalHomePage/>
        }
    }


}


export default React.memo(withRouter(PageManager));
