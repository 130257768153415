import * as React from "react";
import {useState} from "react";
import {useParams, withRouter} from "react-router";
import RequestList from "../listing";
import RequestForm from "./core";
import NotificationForm from "../../notify";
import pages from "../../../../../constants/options/pages";
import actions from "../../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";
import ReportsPageManager from "../../../reports/request";


const RequestPageManager = () => {
    const {page, action} = useParams();
    const [view, setView] = useState('')
    const dispatch = useDispatch()
    const options = {options: [page]}

    dispatch(actions.resetItem(options))


    switch (page) {
        case pages.REQUEST_REGISTRATION_PAGE:
            return <RequestForm {...{setView, view}}/>
        case pages.NOTIFICATION_REGISTRATION_PAGE:
            return <NotificationForm {...{setView, view}}/>
        case pages.REPORT_MODULE:
            return <ReportsPageManager {...{setView, view}}/>
        default:
            return <RequestList {...{setView, view}}/>

    }
}

export default React.memo(withRouter(RequestPageManager))

