import React, {useContext} from "react"
import {withRouter} from "react-router";
import AsyncInputSearch from "../../../../common/utils/AsyncInputSearch";
import CheckBoxFilter from "../../../../common/utils/CheckBoxFilter";
import {FormattedMessage, injectIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {GlobalContext} from "../../../../index";
import {getIntlMsg} from "../../../../../constants/utils";
import CSVButton from "../../../../common/utils/csvbutton";

let Searchbar = injectIntl(props => {

    const dispatch = useDispatch();

    const {items, showModal} = useContext(GlobalContext);
    const {intl, formView, setFormView, options, setData, format} = props
    const criteria = {options: ['indicators', 'names']};
    const resetCriteria = {options: ['indicator']};

    return (
        <div className="mb-7 bg-danger-o-20 pt-5 pb-5 pl-5 pr-5">
            <div className="row align-items-center">

                <div className="col-sm-6">
                    <AsyncInputSearch {...{options}}/>
                </div>

                <div className="col-sm-2">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>

                <div className="col-sm-2">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>
                <div className="col-sm-2">
                    <div className="btn-group btn-group-sm" role="group">
                        <button id="options" type="button"
                                className="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FormattedMessage id={`app.label.actions`}/>
                        </button>


                        <div className="dropdown-menu" aria-labelledby="options">
                            <span className="dropdown-item cursor-pointer"
                                  title={getIntlMsg(intl, `app.label.send_to_reception`)}>
                                    <span className="flaticon2-printer mr-2"/>
                                    <FormattedMessage id={`app.label.print`} defaultMessage={`Export CSV`}/>
                            </span>

                            <span className="dropdown-item cursor-pointer"
                                  title={getIntlMsg(intl, `app.label.send_to_reception`)}>
                                     <CSVButton {...{items: []}}/>
                            </span>
                        </div>


                    </div>

                </div>
            </div>
        </div>

    );
})
export default React.memo(withRouter(Searchbar))
