import * as React from "react";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {reduxForm} from "redux-form";
import {connect, useDispatch, useSelector} from "react-redux";
import {validate} from "./validator";
import SubHeader from "../../common/subheader";
import SymptomForm from "./symptom";
import TravelForm from "./travel";
import InvestigatorForm from "./investigator";
import DiagnosticForm from "./diagnostic";
import DemographicForm from "./demographic";
import {getPatientValues} from "../../../../constants/options/subheaderValues";
import {useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import PersonalInfosForm from "./core/personal";


const NotificationForm = injectIntl((props) => {

    const {intl, setView, view} = props;
    const dispatch = useDispatch();
    const {page,secondLevelPage } = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const options = {intl, options: ['requests'], item: {id: secondLevelPage}}


    useEffect(() => {
            dispatch(actions.loadItem(options))
    }, [secondLevelPage]);


    const {item, loading,savingOrUpdating,status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
    }));





    const tabIds = [
        {id: "#personal", label: "personal_infos", icon: "flaticon2-chat-1", status: "active"},
        {id: "#social", label: "social_infos", icon: "flaticon2-chat-1", status: ""},
        {id: "#symptom", label: "symptoms_signs", icon: "flaticon2-chat-1", status: ""},
        {id: "#travel", label: "travel_history", icon: "flaticon2-chat-1", status: ""},
        {id: "#diagnostic", label: "diagnostic", icon: "flaticon2-chat-1", status: ""},
        {id: "#investigator", label: "investigator", icon: "flaticon2-chat-1", status: ""}
    ]

    const tabs = [
        <PersonalInfosForm key={0}/>,
        <DemographicForm key={1}/>,
        <SymptomForm key={2}/>,
        <TravelForm key={3}/>,
        <DiagnosticForm key={4}/>,
        <InvestigatorForm key={5}/>
    ];


    return (
        <React.Fragment>
            <SubHeader {...{changeView: setView,view, btnOptions: getPatientValues}} noButton/>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-header card-header-tabs-line">
                            <div className="card-toolbar">
                                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                                    {tabIds.map((item, i) =>
                                            <li className="nav-item" key={i}>
                                                <a className={`nav-link ${item.status}`} data-toggle="tab" href={item.id}>
                                                    <span className="nav-icon">
                                                        <i className={item.icon}/>
                                                    </span>
                                                    <span className="nav-text">
                                                        <FormattedMessage id={`app.label.${item.label}`}/>
                                                    </span>
                                                </a>
                                            </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                {tabs.map((item, i) => item)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});


const mapStateToProps = ({observationReducer}) => {
    const item = observationReducer.item;
    const defaultState = {}


    return {
        initialValues: item !== null ? {...item} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "notificationForm",
    enableReinitialize: true,
    validate,
})(NotificationForm))));

