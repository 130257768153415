import React from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderCheckbox, renderField, renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useSelector} from "react-redux";

let FormItems = ({validate}) => {

    const symptomStatus = ['yes', 'no', 'unknown']
    const habits_comorbidity = [
        'hta', 'respiratory_illnesses', 'hiv', 'tb', 'Liver_cirrhosis', 'malnutrition',
        'heart_disease', 'kidney_disease', 'obesity_bmi_grt_35', 'allergic', 'smoking',
        'alcoholic',
    ]
    const symptoms = [['fever', 'aches', 'cough'], ['asthenia', 'diarrea', 'headache']]
    const otherSymptoms = ['ageusia', 'anosmia']

    const {observation} = useSelector(state => ({
        observation: state.form?.observationForm?.values,
    }));


    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-4">
                    <Field id="symptoms" name="symptoms" component={renderRadio} items={['yes', 'no']}
                           label="person_has_symptoms" validate={validate.error} required/>
                </div>
                {observation?.symptoms === 'yes' && <div className="col-sm-8">
                    <Field className="form-control form-control-sm" id="symptoms_start_date" name="symptoms_start_date"
                           component={renderField} type="date" label="app.label.symptoms_start_date"
                           placeholder="app.placeholder.symptoms_start_date" validate={validate.error}/>
                </div>}
            </div>

            <hr/>
            <div className="row mt-3">
                <div className="col-sm-12 mb-3">
                    <h4><FormattedMessage id={`app.label.signs_symptoms`}/></h4>
                </div>
            </div>
            {symptoms.map((item, i) =>
                <div className="row mt-3" key={i}>
                    {item.map((element, i) =>
                        <div className="col-sm-4" key={i}>
                            <Field id={element} name={element} component={renderRadio} items={symptomStatus}
                                   label={element} validate={validate.error}/>
                        </div>
                    )}
                </div>
            )}

            <div className="row mt-2">
                {otherSymptoms.map((element, i) =>
                    <div className="col-sm-4" key={i}>
                        <Field id={element} name={element} component={renderRadio} items={symptomStatus}
                               label={element} validate={validate.error}/>
                    </div>
                )}
                <div className="col-sm-4">
                    <Field
                        className="form-control form-control-sm" id="other" name="other_symptoms_signs"
                        component={renderField} type="text" label="other_symptoms_signs"
                        placeholder="other_symptoms_signs" validate={validate.error}/>
                </div>
            </div>

            <hr/>
            <div className="row mt-3">
                <div className="col-sm-12 mb-3">
                    <h4><FormattedMessage id={`app.label.comorbidity_habits`}/></h4>
                </div>
                {habits_comorbidity.map((item, i) =>
                    <div className="col-sm-2" key={i}>
                        <Field component={renderCheckbox}
                               name={item} label={item} id={item}
                               validate={validate.error}
                        />
                    </div>
                )}

                <div className="col-sm-12">
                    <Field className="form-control form-control-sm" id="other" name="other_habits_comorbidity"
                           component={renderField} type="text" label="other_habits_comorbidity"
                           placeholder="other_habits_comorbidity" validate={validate.error}/>
                </div>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
