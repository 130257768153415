import React from 'react'
import PageHeader from "../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../common/report/PageFooter";


const styles = {
    wrapper: {
        width: "800px",
        margin: "100px auto",
        fontFamily: "Helvetica",
        fontSize: "16px"
    },

    rowThreeColumn: {
        display: 'flex',
        columnSize: {
            width: '350px'
        }
    },

    rowThreeColumnMargin: {
        display: 'flex',
        columnSize: {
            width: '400px',
            marginRight: "50px"
        },

    },
    titleWeight: {fontWeight: 'bold'},
    threeColumnTd: {width: "325px"},
    rowMargin: {marginTop: "100px"},
}


class ResultSheet extends React.Component {


    changeColor(status) {
        if (status === 'POSITIVE')
            return "red"

        if (status === 'NEGATIVE')
            return "green"

        if (status === 'UNKNOWN')
            return "blue"
    }

    render() {
        const {item} = this.props;
        const isNegative = item?.diagnostic?.result?.status === 'NEGATIVE' ? 'green' : 'red'
        return (

            <div className="result_sheet">
                <PageHeader/>
                <div style={styles.wrapper}>

                    <h3 style={{fontWeight: "bold", marginBottom: "15px", textAlign: "center"}}>
                        <FormattedMessage id="app.label.result_sheet" defaultValue="Result Sheet"/>
                    </h3>
                    <hr/>
                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.laboratory_code_acc'}/> :
                            </h5>
                            <span>{item?.codeLaboratory.toUpperCase()}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.specimen_center'}/> :
                            </h5>
                            <span>{item?.specimenCenter?.name}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.today_date'}/> :
                            </h5>
                            <span>{moment(new Date()).format('DD/MM/YYYY')}</span>
                        </div>

                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>
                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.firstname'}/> :
                            </h5>
                            <span>{item?.applicant?.personalInfos?.firstName}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.lastname'}/> :
                            </h5>
                            <span>{item?.applicant?.personalInfos?.lastName.toUpperCase()}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.passport_no'}/> :
                            </h5>
                            <span>{item?.applicant?.socialInfos?.identifier[0]?.value.toUpperCase()}</span>
                        </div>

                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>


                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.dob_acc'}/> :
                            </h5>
                            <span>
                                {moment(item?.applicant?.personalInfos?.dob).format('DD/MM/YYYY')}
                            </span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.age'}/> :
                            </h5>
                            <span>
                                <FormattedMessage id={item?.applicant?.personalInfos?.age}/>
                            </span>
                        </div>


                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.gender'}/> :
                            </h5>
                            <span>
                                <FormattedMessage id={`app.label.${item?.applicant?.personalInfos?.gender}`}/>
                            </span>
                        </div>
                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.date_of_specimen'}/> :
                            </h5>
                            <span>{moment(item?.diagnostic?.specimens[0]?.collectionDate).format('DD/MM/YYYY')}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.specimen_type'}/> :
                            </h5>
                            <span>
                                {item?.diagnostic?.specimens.map((specimen, i) =>
                                    <ul style={{display: "inline-block", marginRight: "20px"}}>
                                        <li style={{listStyle: "square"}}>{specimen?.category?.description}</li>
                                    </ul>
                                )}
                            </span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.test_request_type'}/> :
                            </h5>
                            <span>{item?.testType.name}</span>
                        </div>


                    </div>


                    <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>

                        <div style={{...styles.rowThreeColumn.columnSize, color: 'white', fontSize: "20px"}}>
                            <h5 style={styles.titleWeight}>
                                {/*<FormattedMessage id={'app.label.result'}/> :*/}
                            </h5>
                            <span style={{
                                textTransform: 'uppercase',
                                fontWeight: "bold",
                                backgroundColor: isNegative,
                                padding: "10px"
                            }}>
                                 <FormattedMessage id={`app.label.${item?.diagnostic?.result?.status}`}/>
                            </span>
                        </div>


                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                        <div style={{width: "100%"}}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.remark'} defaultMessage="remark"/> :
                            </h5>
                            {item?.remark}
                            <hr style={{marginTop: "30px"}}/>
                        </div>

                    </div>

                    <div style={{...styles.rowThreeColumnMargin, marginTop: "200px"}}>

                        <div style={styles.rowThreeColumnMargin.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.technician_signature'} defaultMessage="remark"/> :
                            </h5>
                            <hr style={{marginTop: "50px"}}/>
                        </div>

                        <div style={styles.rowThreeColumnMargin.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.supervisor_signature'} defaultMessage="remark"/> :
                            </h5>
                            <hr style={{marginTop: "50px"}}/>
                        </div>
                    </div>
                </div>
                <PageFooter {...{item}}/>
            </div>
        )
    }


}

export default ResultSheet;

