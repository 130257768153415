import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {renderCheckbox, renderField, renderRadio, renderSelect} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../constants/utils";
import {countries} from "../../../../../constants/options/countries";

let renderTrip =injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd} = props
    const  validate = {}


    return (
        <div className="row">
            <li>
                <button type="button" onClick={() => fields.push()}>
                    Ajouter Voyage
                </button>
            </li>
            
            {fields.map((trip, index) => (
                <div className="col-sm-12" key={index}>

                    <div className="row">
                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control" id="trip_local_country_cate" name={`${trip}.country`}
                                   component={renderSelect} validate={validate.error}
                                   label="trip_country" placeholder="trip_country" items={countries}
                            />
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${trip}.city`}
                                   component={renderField} type="text" label="trip_city"
                                   placeholder="trip_city" validate={validate.error}/>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${trip}.arrivalDate`}
                                   component={renderField} type="date" label="trip_arrival_date"
                                   placeholder="trip_arrival_date" validate={validate.error}/>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${trip}.departureDate`}
                                   component={renderField} type="date" label="trip_departure_date"
                                   placeholder="trip_departure_date" validate={validate.error}/>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${trip}.localCountryDate`}
                                   component={renderField} type="date" label="trip_local_country_date"
                                   placeholder="trip_local_country_date" validate={validate.error}/>
                        </div>
                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl,'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            {error && <li className="error">{error}</li>}
        </div>
    );
})
export default React.memo(withRouter(renderTrip))
