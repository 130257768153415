import * as React from "react";
import {Link} from "react-router-dom";
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl} from "react-intl";


const PrimaryMenuItem = injectIntl((props) =>  {
    const {intl, url='#',title, icon, active, target, hasTarget} = props
    return (
        <li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body"
            data-boundary="window" title={getIntlMsg(intl, `app.label.${title}`)}>

            {hasTarget ?
                <Link to={url}
                      className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white ${active ? 'active': ''}`}
                      data-toggle="tab" data-target={target} role="tab">
				<span className="svg-icon svg-icon-xl">
                    {icon}
				</span>
                </Link>

                :

                <Link to={url} className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white`}>
				    <span className="svg-icon svg-icon-xl"> {icon}</span>
                </Link>

            }




        </li>);
})

export default React.memo(PrimaryMenuItem)

