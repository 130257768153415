const singleParams = {
    item: null,
    loadingItem: false,
    status:null
}

const counterParams = {
    counter: 0,
    loading: false,
}

const lovParams = {
    lovItems: [],
    loadingLov: false,
}

const dropDownParams = {
    dropDownItems: [],
    loadingDropDown: false,
}

const baseParams = {
    ...singleParams,
    items: [],
    loadingItems: false,
    pageParam: null
}

const moduleParams = {
    ...baseParams,
    firstItem: null,
    deleting: false,
    savingOrUpdating: false,
};

const otherSettingParams = {
    setting: null,
    loadingSetting: false,
}


export default {

    coreReducer: {
        locale: "fr",
        currency: "gds",
        country: "ht"
    },
    proxyReducer: {},

    patientReducer: {...moduleParams, ...counterParams},
    requestReducer: {...moduleParams, ...counterParams},
    resultReducer: {...moduleParams, ...counterParams},
    currencyReducer: {...moduleParams, ...counterParams},
    institutionReducer: {...moduleParams, ...counterParams},
    itemPriceReducer: {...moduleParams, ...counterParams},
    testIndicatorReducer: {...moduleParams, ...counterParams},
    spCenterReducer: {...moduleParams, ...counterParams},
    settingsReducer: {...moduleParams, ...counterParams, ...otherSettingParams},
    dbReducer: {
        request: {...moduleParams},
        security: {...moduleParams},
        financial: {...moduleParams},
        leadership: {...moduleParams},
        indicator: {...moduleParams},
    },

    categoryReducer: {
        ...moduleParams, ...lovParams, ...dropDownParams,
        customerTypes: [],
        loadingCustomerTypes: false,
        specimenTypes: [],
        loadingSpecimenTypes: false,
        documentTypes: [],
        loadingDocumentTypes: false
    },

    observationReducer: {...moduleParams},


};
