import React from 'react'
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../../common/report/PageFooter";


const styles = {
    wrapper: {
        width: "800px",
        margin: "70px auto",
        fontFamily: "Helvetica Neue"
    },

    rowThreeColumn: {
        display: 'flex',
        columnSize: {
            width: '225px'
        }
    },
    titleWeight: {fontWeight: 'bold', fontSize: '16px'},
    threeColumnTd: {width: "325px"},
    rowMargin: {marginTop: "150px"},
    textLabel: {fontSize: '15px'},
    textTitle: {fontSize: '16px'}

}


class RequestForm extends React.Component {


    render() {
        const {item} = this.props;

        return (

            <div className="request_form">
                <PageHeader {...{item}}/>
                <div style={styles.wrapper}>

                    <h3 style={{fontWeight: "bold", marginBottom: "15px"}}>Information Individuelle</h3>
                    <hr/>
                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>
                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.laboratory_code_acc'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.codeLaboratory?.toUpperCase()}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.firstname'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.applicant?.personalInfos?.firstName}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.lastname'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.applicant?.personalInfos?.lastName}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.gender'}/> :
                            </h5>
                            <span style={styles.textLabel}>
                                <FormattedMessage id={`app.label.${item?.applicant?.personalInfos?.gender}`}/>
                            </span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.dob_acc'}/> :
                            </h5>
                            <span style={styles.textLabel}>
                                {moment(item?.applicant?.personalInfos?.dob).format('DD/MM/yyyy')}
                            </span>
                        </div>

                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>


                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.passport_no'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.applicant?.socialInfos?.identifier[0]?.value}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.phone'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.applicant?.socialInfos?.phones[0]?.no}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.email'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.applicant?.socialInfos?.emails[0]?.current}</span>
                        </div>


                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.nationality'}/> :
                            </h5>
                            <span style={styles.textLabel}>
                                <FormattedMessage id={item?.applicant?.socialInfos?.nationality}/>
                            </span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.nickname'}/> :
                            </h5>
                            <span style={styles.textLabel}>
                               {item?.applicant?.personalInfos?.nickname}
                            </span>
                        </div>

                    </div>
                </div>

                <div style={styles.wrapper}>

                    <h3 style={{fontWeight: "bold", marginBottom: "15px"}}>Information Demande</h3>
                    <hr/>
                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.specimen_center_acc'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.specimenCenter?.name}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.test_type'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.testType?.name}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.appointment_date_acc'}/> :
                            </h5>
                            <span style={styles.textLabel}>{moment(item?.appointmentDate).format('DD/MM/yyyy')}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.symptoms'}/> :
                            </h5>
                            <span>
                               <span style={styles.textLabel}>{item?.symptoms ? 'OUI' : 'NON'}</span>
                            </span>
                        </div>

                    </div>

                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>


                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.departure'}/> :
                            </h5>
                            <span style={styles.textLabel}>{moment(item?.departure).format('DD/MM/yyyy')}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.destination'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.destination}</span>
                        </div>

                    </div>
                </div>
                <PageFooter {...{item}}/>

                <div style={{borderBottom: "2px dashed #cecece", marginTop: "30px"}}/>

                <PageHeader/>
                <div style={styles.wrapper}>

                    <h3 style={{fontWeight: "bold", marginBottom: "15px", textAlign: "center"}}>
                        Détails de facturation
                    </h3>
                    <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>
                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.trx_code'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.payment?.trxCode}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.amount'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.payment?.amount} GDES</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.payment_type'}/> :
                            </h5>
                            <span style={styles.textLabel}>{item?.payment?.paymentType}</span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.payed'}/> :
                            </h5>
                            <span>
                                <span style={styles.textLabel}>{item?.payment?.payed ? 'OUI' : 'NON'}</span>
                            </span>
                        </div>

                        <div style={styles.rowThreeColumn.columnSize}>
                            <h5 style={styles.titleWeight}>
                                <FormattedMessage id={'app.label.today_date'}/> :
                            </h5>
                            <span style={{fontSize: '15px'}}>
                                {moment().format('DD/MM/YY')}
                            </span>
                        </div>

                    </div>

                </div>
                <div style={{marginBottom: "50px"}}/>
                <PageFooter {...{item}}/>
            </div>
        )
    }
}

export default RequestForm;

