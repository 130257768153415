import * as React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {useContext} from "react";
import {getDashboardItems} from "../../../../constants/options/secondaryMenuItems";
import ModuleItem from "../footer/module";
import {GlobalContext} from "../../../index";
import {canDisplay} from "../../../../constants/utils/security";


const SecondaryDashboard = injectIntl((props) => {
    const {intl, tab} = props
    const {userSession: {roles, name, keycloak}, institution: {acronym}} = useContext(GlobalContext);

    const hasAccess = () => {
        return roles.includes('FINANCIAL_OFFICER') || roles.includes('LEADERSHIP_MEMBER')
    }

    return (
        <div className={`tab-pane fade`}
             id="dashboard">
            <div className="aside-menu-wrapper flex-column-fluid px-3 px-lg-10 py-5"
                 id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="aside-menu min-h-lg-800px" data-menu-vertical="1"
                     data-menu-scroll="1">
                    <ul className="menu-nav">
                        {getDashboardItems(acronym).map((item, i) =>
                            canDisplay(roles, item.module) && <ModuleItem {...item} key={i}/>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
})

export default React.memo(withRouter(SecondaryDashboard))

