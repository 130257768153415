import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import AboutModal from "./about";
import TeamModal from "./team";
import ContactModal from "./contact";


const Index = ({institution}) => {

    return (

        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted font-weight-bold mr-2">2021©</span>
                    <a href="http://zanmilasante.org" target="_blank"
                       className="text-dark-75 text-hover-primary">{institution.description}</a>
                </div>
                <div className="nav nav-dark order-1 order-md-2">
                    <Link to={'#'} className="nav-link pr-3 pl-0" data-toggle="modal" data-target="#about">
                        <FormattedMessage id={`app.label.about`}/>
                    </Link>
                    <Link to={'#'} className="nav-link pr-3 pl-0" data-toggle="modal" data-target="#team">
                        <FormattedMessage id={`app.label.team`}/>
                    </Link>
                    <Link to={'#'} className="nav-link pr-3 pl-0" data-toggle="modal" data-target="#contact">
                        <FormattedMessage id={`app.label.contact`}/>
                    </Link>
                </div>

                <AboutModal/>
                <TeamModal/>
                <ContactModal/>
            </div>

        </div>
    )
}

export default React.memo(Index)

