import React, {useState} from "react"
import {injectIntl, FormattedMessage} from "react-intl"
import {getIntlMsg} from "../../../constants/utils";
import Field from "redux-form/lib/Field";


export class FileInput extends React.Component {

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split("base64,")[1]);
            reader.onerror = error => reject(error);
        });
    }

    onFileChange = async(e) => {
        const {input} = this.props
        const targetFile = e.target.files[0]
        if (targetFile) {
            const val = await this.getBase64(targetFile)
            const fileName = targetFile.name;
            const current = {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val
            }

            input.onChange(current)
        } else {
            input.onChange(null)
        }
    }

    render() {

        return (


            <input
                type="file"
                onChange={this.onFileChange}
            />
        )
    }
}


export const renderFreeField = injectIntl(
    ({intl, input, label, style, blockStyle, placeholder, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className="input-group">
            <input {...input}
                   className="" style={{border: "0 none", ...style}} readOnly={readonly} id={id} type={type}
                   placeholder={getIntlMsg(intl, `app.placeholder.${placeholder}`)}/>
            {touched && error &&
            <div className="text-primary">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error}/>
                </small>
            </div>
            }
        </div>
    )
);


export const renderDate = injectIntl(
    ({intl, input, label, style, placeholder, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className={`form-group`}>
            <label htmlFor={label} className={`${required ? "" : ''}`}>
                <b><FormattedMessage id={`app.label.${label}`}/></b>
                {required && <span className="text-primary"> * </span>} :
            </label>
            <input type="text" className="form-control" {...input}
                   id="kt_datepicker_1" readOnly="readonly"
                   placeholder={getIntlMsg(intl, `app.placeholder.${placeholder}`)}
            />
            {touched && error &&
            <div className="text-primary">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error}/>
                </small>
            </div>
            }
        </div>
    )
);

export const renderField = injectIntl(
    ({intl, input, label, style, placeholder, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className={`form-group`}>
            <label htmlFor={label} className={`font-weight-bold ${required ? "" : ''}`}>
                <FormattedMessage id={`app.label.${label}`}/>
                {required && <span className="text-primary"> * </span>} :
            </label>
            {type === "textarea" ? (
                    <div>
                <textarea {...input} placeholder={getIntlMsg(intl, placeholder)}
                          className={`form-control ${style}`}/>

                    </div>
                ) : (
                    <input {...input}
                           className={`form-control ${style}`}
                           readOnly={readonly}
                           id={id} placeholder={getIntlMsg(intl, `app.placeholder.${placeholder}`)}
                           type={type}/>)}
            {touched && error &&
            <div className="text-primary">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error}/>
                </small>
            </div>
            }
        </div>
    )
);


export const renderSelect = injectIntl(
    ({intl, input, label, placeholder, i18nable = false, type, id, readonly, items, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className="form-group">
            <label htmlFor={label} className={` font-weight-bold ${required ? "text-primary" : ''}`}>
                <FormattedMessage id={`app.label.${label}`}/> :
            </label>
            <select className="form-control" {...input}>
                <option value=''>
                    {getIntlMsg(intl, `app.label.choose_${label}`)}
                </option>
                {items.map((item, i) => <option value={item ?.code} key={i}>
                    {item && item ?.name.toUpperCase()}
                </option>)}
            </select>
            {touched && error && <span className="form-text text-primary">
                 <FormattedMessage id={error}/>
            </span>}
        </div>
    )
);

export const renderDestination = injectIntl(
    ({intl, input, label, placeholder, i18nable = false, type, id, readonly, items, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className="form-group">
            <label htmlFor={label} className={` font-weight-bold ${required ? "text-primary" : ''}`}>
                <FormattedMessage id={`app.label.${label}`}/> :
            </label>
            <select className="form-control" {...input}>
                {items.map((item, i) => <option value={item ?.name} key={i}>
                    {item && item ?.name.toUpperCase()}
                </option>)}
            </select>
            {touched && error && <span className="form-text text-primary">
                 <FormattedMessage id={error}/>
            </span>}
        </div>
    )
);


export const FreeSelect = injectIntl(
    ({intl, input, label, handleChange, placeholder, i18nable = false, type, id, readonly, items, required = false}) => (
        <div className="form-group">
            <label htmlFor={label} className={` font-weight-bold ${required ? "text-primary" : ''}`}>
                <FormattedMessage id={`app.label.${label}`}/> :
            </label>
            <select className="form-control" {...input} onChange={(e) => handleChange(e.target.value)}>
                <option value=''>
                    {getIntlMsg(intl, `app.label.choose_${label}`)}
                </option>
                {items.map((item, i) => <option value={item ?.id} key={i}>
                    {item && item ?.name.toUpperCase()}
                </option>)}
            </select>
            {/*{touched && error && <span className="form-text text-primary">*/}
            {/*     <FormattedMessage id={error} />*/}
            {/*</span>}*/}
        </div>
    )
);


// export const renderRadio = ()=>{}


export const renderRadio = injectIntl(
    ({intl, label, selected, name, items, input, required = false, meta: {asyncValidating, touched, error}}) => {

        return <div className="form-group">
            <label htmlFor={label} className={`${required ? "" : ''}`}>
                <FormattedMessage id={`app.label.${label}`}/>
                {required && <span className="text-primary"> * </span>} :
            </label>
            <div className="radio-inline">
                {items.map((element, i) =>
                    <label className="radio radio-lg" key={i}>
                        <input type="radio" {...input} name={name} value={element}
                        />
                        <span/>
                        <FormattedMessage id={`app.label.${element}`}/>
                    </label>
                )}
            </div>
            {touched && error && <span className="form-text text-primary">
                <FormattedMessage id={error}/>
            </span>}
        </div>
    }
);

export const renderCheckbox = injectIntl(
    ({intl, input, label, placeholder, type, id, readonly, item, required = false, meta: {asyncValidating, touched, error}}) => (

        <div className="form-group">
            <div className="checkbox-inline">
                <label className="checkbox checkbox-square divider">
                    <input type="checkbox" {...input} readOnly={readonly}/>
                    <span/>
                    <FormattedMessage id={`app.label.${label}`}/>
                </label>
            </div>
            {touched && error && <span className="form-text text-primary">
                <FormattedMessage id={error}/>
            </span>}
        </div>
    )
);


//NOTE  Custom row common field
export const renderRowField = injectIntl(
    ({intl, input, label, placeholder, labelSize, inputSize, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className="form-group row">
            <label htmlFor={label} className={`${required ? "text-primary" : ''} ${labelSize} form-label`}
                   style={{paddingTop: '20px', paddingRight: 0}}>
                <FormattedMessage id={label}/> :
            </label>
            <div className={inputSize}>
                <input {...input}
                       className="form-control"
                       readOnly={readonly}
                       id={id} placeholder={getIntlMsg(intl, placeholder)}
                       type={type}/>
            </div>

            {touched && error &&
            <div className="">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error}/>
                </small>
            </div>
            }
        </div>
    )
);

