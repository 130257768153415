import * as React from "react";
import actions from "../../../../../../../thunks/common/coreThunks";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {Link, useParams} from "react-router-dom";
import {useContext} from "react";
import {GlobalContext} from "../../../../../../index";
import {useDispatch} from "react-redux";
import DeleteItem from "../../../../../common/modal/DeleteItem";
import {getIntlMsg} from "../../../../../../../constants/utils";
import ChangeStateModal from "../../../../../common/modal/ChangeState";


const ReceptBtn = injectIntl(props => {

    const {institution: {acronym}} = useContext(GlobalContext);
    const {module} = useParams()
    const dispatch = useDispatch()
    const {intl, handleStateChange, id} = props

    const params = {intl, item: {id}, options: [module]}


    return (

        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
            <Link to={`/${acronym}/${module}/registration/staff/${id}/edit`} className="btn btn-light-primary"
                  onClick={() => {
                      dispatch(actions.loadItem(params))
                  }}>
                <FormattedMessage id={`app.label.adjust_request`}/>
            </Link>
            <button type="button" className="btn btn-light-primary" data-toggle="modal" data-target={`#specimens${id}`}
                    title={getIntlMsg(intl, `app.label.send_to_specimens`)}>
                <FormattedMessage id={`app.label.send_to_specimens`}/>
            </button>

            <ChangeStateModal {...{id, title:'specimens', handleStateChange}}/>
        </div>
    );
});


export default React.memo(withRouter(ReceptBtn))
