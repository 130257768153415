import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {renderCheckbox, renderField, renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../constants/utils";

let renderContact =injectIntl(props => {
    const {intl, fields, meta: {error}} = props
    const  validate = {}


    return (
        <div className="row">
            <li>
                <button type="button" onClick={() => fields.push()}>
                    Ajouter contact
                </button>
            </li>
            
            {fields.map((contact, index) => (
                <div className="col-sm-12" key={index}>

                    <div className="row">
                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-3">
                            <Field className="form-control form-control-sm" id="other" name={`${contact}.id`}
                                   component={renderField} type="text" label="covid_contact_id"
                                   placeholder="covid_contact_id" validate={validate.error}/>
                        </div>
                        <div className="col-sm-3">
                            <Field className="form-control form-control-sm" id="other" name={`${contact}.fullName`}
                                   component={renderField} type="text" label="covid_contact_full_name"
                                   placeholder="covid_contact_full_name" validate={validate.error}/>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${contact}.firstTouch`}
                                   component={renderField} type="date" label="covid_contact_first_touch"
                                   placeholder="covid_contact_first_touch" validate={validate.error}/>
                        </div>
                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="other" name={`${contact}.lastTouch`}
                                   component={renderField} type="date" label="covid_contact_last_touch"
                                   placeholder="covid_contact_last_touch" validate={validate.error}/>
                        </div>
                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl,'app.label.remove_contact')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            {error && <li className="error">{error}</li>}
        </div>
    );
})
export default React.memo(withRouter(renderContact))
