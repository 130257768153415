import React, {useEffect} from "react"
import {Field} from "redux-form";
import {renderDestination, renderField, renderSelect} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {validate} from "../validator";
import {countries} from "../../../../../../../constants/options/countries";
import actions from "../../../../../../../thunks/common/coreThunks";
import {FormattedMessage, injectIntl} from "react-intl";
import {getHours, getIntlMsg} from "../../../../../../../constants/utils";

let FormItems = injectIntl(props => {

    const {intl, item, touched, error} = props

    const dispatch = useDispatch();


    const criteria = {options: ['specimencenters']};
    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        items: state.spCenterReducer.items,
        loading: state.spCenterReducer.loadingItems,
    }));

    const {values} = useSelector(state => ({
        values: state.form?.requestForm?.values
    }));


    const isTraveler = values.type === 'TRAVELER'



    const getAnalyseById = id => {

        if (items) {
            const values = [...items]
            return values.find(value => value.id === id)?.analyses || []
        }
    }

    return !loading && items && (
        <React.Fragment>


            <div className="row">

                {isTraveler &&
                <>
                    <div className="col-sm-4">
                        <Field className="form-control form-control-sm" id="departure"
                               name="departure" component={renderField} type="date"
                               label="departure" placeholder="departure"
                               validate={validate.error} required/>
                    </div>
                    <div className="col-sm-4">
                        <Field id="destination" name="destination"
                               component={renderDestination} label="destination" validate={validate.error}
                               items={countries}/>
                    </div>
                </>
                }

                <div className="col-sm-4">
                    <div className="form-group">
                        <label>
                            <FormattedMessage id={`app.label.do_you_have_symptoms`}/>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {['yes', 'no'].map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input" name="symptoms" value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>


                <div className={`${isTraveler ? 'col-sm-8' : 'col-sm-4'}`}>
                    <div className="form-group">
                        <label htmlFor="specimen_center" className="font-weight-bold">
                            <FormattedMessage id="app.label.specimen_center"/> :
                        </label>
                        <Field name="specimenCenter" component="select" className="form-control">
                            <option value=''>
                                {getIntlMsg(intl, `app.label.choose_specimen_center`)}
                            </option>
                            {items.map((item, i) => <option value={item?.id} key={i}>
                                {item && item?.name.toUpperCase()}
                            </option>)}
                        </Field>
                        {touched && error &&
                        <span className="form-text text-primary">
                                <FormattedMessage id={error}/>
                        </span>}
                    </div>

                </div>

                <div className="col-sm-4">
                    <div className="form-group">
                        <label htmlFor="test_type" className="font-weight-bold">
                            <FormattedMessage id="app.label.test_type"/> :
                        </label>
                        <Field name="testType" component="select" className="form-control">
                            <option value=''>
                                {getIntlMsg(intl, `app.label.choose_test_type`)}
                            </option>
                            {getAnalyseById(values?.specimenCenter).map((item, i) =>
                                <option value={item?.id} key={i}>
                                    {item && item?.description.toUpperCase()}
                                </option>
                            )}
                        </Field>
                        {touched && error &&
                        <span className="form-text text-primary">
                                <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>
            </div>

            {isTraveler && <div className="row">
                <div className="col-sm-8">
                    <Field className="form-control form-control-sm" id="appointment_date"
                           name="appointmentDate" component={renderField} type="date"
                           label="appointment_date" placeholder="appointment_date"
                           validate={validate.error} required/>
                </div>
                <div className="col-sm-4">
                    <Field id="appointment_hour" name="appointmentHour"
                           component={renderSelect} label="appointment_hour" items={getHours(values.appointmentDate)}/>
                </div>
            </div>}

        </React.Fragment>

    );
})
export default React.memo(withRouter(FormItems))
