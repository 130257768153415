import React from "react"
import {Field} from "redux-form";
import {renderField, renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

let FormItems = ({item, validate}) => {

    const isolationLocations = ['isolation_home', 'isolation_hospital']
    const prcResults = ['positive', 'negative', 'unknown']
    const releasedState = ['restored', 'dead', 'loss_of_sight']
    const specimenStatus = ['yes', 'no']
    const ventilationStatus = ['yes', 'no']
    const intensiveCareStatus = ['yes', 'no']
    const specimenCategories = ['nasopharyngee', 'propharyngeal', 'serum']
    const clinicalStates = ['light', 'moderate', 'strict']


    const {observation} = useSelector(state => ({
        observation: state.form?.observationForm?.values,
    }));


    return (
        <div className={`custom_fieldset mt-4`}>
            <div className="row">

                <div className="col-sm-5">
                    <h4><FormattedMessage id={`app.label.test_specimen`}/></h4>
                    <hr/>
                    <div className="row mt-3">
                        {specimenCategories.map((item, i) =>
                            <React.Fragment key={i}>
                                <div className="col-sm-7">
                                    <Field id={`specimen_${item}`} name={`specimen_${item}`} component={renderRadio}
                                           items={specimenStatus} label={`specimen_${item}`} validate={validate.error}/>
                                </div>
                                <div className="col-sm-5">
                                    <Field className="form-control form-control-sm" id="symptoms_start_date"
                                           name={`specimen_${item}_date`}
                                           component={renderField} type="date" label={`specimen_date`}
                                           placeholder={`specimen_${item}_date`}
                                           validate={validate.error}/>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <Field className="form-control form-control-sm" id="symptoms_start_date"
                                   name={`laboratory_specimen_reception_date`}
                                   component={renderField} type="date" label={`laboratory_specimen_reception_date`}
                                   placeholder={`laboratory_specimen_reception_date`} validate={validate.error}/>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-7">
                            <Field id={`pcr_result`} name={`pcr_result`} component={renderRadio}
                                   items={prcResults} label={`pcr_result`} validate={validate.error}/>
                        </div>
                        <div className="col-sm-5">
                            <Field className="form-control form-control-sm" id="pcr_result_date"
                                   name="pcr_result_date"
                                   component={renderField} type="date" label="pcr_result_date"
                                   placeholder="pcr_result_date" validate={validate.error}/>
                        </div>
                    </div>

                </div>


                {observation?.pcr_result === prcResults[0] && <div className="col-sm-6 offset-1">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4><FormattedMessage id={`app.label.others`}/></h4>
                            <hr/>
                            <div className="row mt-3">

                                <div className="col-sm-12">
                                    <Field id="clinical_evolution" name="clinical_evolution" component={renderRadio}
                                           items={clinicalStates} label="clinical_evolution"
                                           validate={validate.error}/>
                                </div>

                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Field id="isolation_location" name="isolation_location"
                                                   component={renderRadio}
                                                   items={isolationLocations} label="isolation_location"
                                                   validate={validate.error}/>

                                        </div>
                                        {observation?.isolation_location === isolationLocations[1] &&
                                        <div className="col-sm-6">
                                            <Field className="form-control form-control-sm" id="symptoms_start_date"
                                                   name="institution"
                                                   component={renderField} type="text" label="institution"
                                                   placeholder="institution"
                                                   validate={validate.error}/>
                                        </div>}
                                    </div>
                                </div>

                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Field className="form-control form-control-sm" id="hospitalization_date"
                                                   name="hospitalization_date"
                                                   component={renderField} type="date" label="hospitalization_date"
                                                   placeholder="hospitalization_date"
                                                   validate={validate.error}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <Field id="ventilation_status" name="ventilation_status"
                                                   component={renderRadio}
                                                   items={ventilationStatus} label="ventilation_status"
                                                   validate={validate.error}/>

                                        </div>
                                        {observation?.ventilation_status === ventilationStatus[0] &&
                                        <div className="col-sm-4">
                                            <Field className="form-control form-control-sm" id="ventilation_day_counter"
                                                   name="ventilation_day_counter"
                                                   component={renderField} type="number" label="ventilation_day_counter"
                                                   placeholder="ventilation_day_counter"
                                                   validate={validate.error}/>
                                        </div>}
                                    </div>
                                </div>

                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <Field id="intensive_care_status" name="intensive_care_status"
                                                   component={renderRadio}
                                                   items={intensiveCareStatus} label="intensive_care_status"
                                                   validate={validate.error}/>

                                        </div>
                                        {observation?.intensive_care_status === intensiveCareStatus[0] &&
                                        <div className="col-sm-8">
                                            <Field className="form-control form-control-sm"
                                                   id="intensive_care_day_counter"
                                                   name="intensive_care_day_counter"
                                                   component={renderField} type="number"
                                                   label="intensive_care_day_counter"
                                                   placeholder="intensive_care_day_counter"
                                                   validate={validate.error}/>
                                        </div>}
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="col-sm-12">
                            <h4><FormattedMessage id={`app.label.exit_test_result`}/></h4>
                            <hr/>
                            <div className="row mt-3">

                                <div className="col-sm-12">
                                    <Field id={`exit_pcr_result`} name={`exit_pcr_result`} component={renderRadio}
                                           items={prcResults} label={`exit_pcr_result`} validate={validate.error}/>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <Field id="patient_released_state" name="patient_released_state"
                                                   component={renderRadio}
                                                   items={releasedState} label="patient_released_state"
                                                   validate={validate.error}/>
                                        </div>
                                        {observation?.patient_released_state === releasedState[0] &&
                                        <div className="col-sm-5">
                                            <Field className="form-control form-control-sm" id="released_date"
                                                   name="released_date"
                                                   component={renderField} type="date" label="released_date"
                                                   placeholder="restored_date" validate={validate.error}/>
                                        </div>}
                                        {observation?.patient_released_state === releasedState[1] &&
                                        <div className="col-sm-5">
                                            <Field className="form-control form-control-sm" id="death_date"
                                                   name="death_date"
                                                   component={renderField} type="date" label="death_date"
                                                   placeholder="death_date" validate={validate.error}/>
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>}
            </div>


        </div>

    );
}
export default React.memo(withRouter(FormItems))
