/**
 * Created by inglouidor on 3/18/21.
 */
import * as React from "react";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch,useSelector} from "react-redux";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {getIntlMsg} from "../../../../../../../constants/utils";
import {FormattedMessage} from "react-intl";
import actions from "../../../../../../../thunks/common/coreThunks";
import {change} from "redux-form";
import moment from 'moment'


const SearchForm = injectIntl((props) => {

    const {intl, sites} = props;
    const dispatch = useDispatch();
    const options ={options: ['indicator-dashboard']}

    const {values} = useSelector(state => ({
        values: state?.form?.searchForm?.values
}));

    const handleChangeFilter=(t,e)=>{
        if(values && values.startDate) {
            dispatch(change("searchForm",t==='start'?"startDate":t==="end"?"endDate":"site",t==="start"?e:t=="end"?e:e))
            const param = `?started=${t==='start'?e:values?.endDate||''}&ended=${t==='end'?e:values?.endDate||''}&name=&site=${t=="site"?e:values ?.site||''}`;
            dispatch(actions.loadItems({...options, param}));
        }
    };


    return (
        <div className="col-md-8">
            <form>
                <div className="row">
                    <div className="col-md-4">
                        <Field class="form-control-sm" id="startDate" name="startDate"
                               component={renderField} type="date" label="start" placeholder="startDate"
                               onChange={(e)=>handleChangeFilter('start',e.target.value)}
                               required/>
                    </div>
                    <div className="col-sm-4">
                        <Field class="form-control-sm" id="endDate" name="endDate"
                               onChange={(e)=>handleChangeFilter('end',e.target.value)}
                               component={renderField} type="date" label="end" placeholder="endDate"
                               required/>
                    </div>

                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="site" className="font-weight-bold">
                                <FormattedMessage id="app.label.healthCenter"/> :
                            </label>
                            <Field name="site" component="select" className="form-control"  onChange={(e)=>handleChangeFilter('site',e.target.value)}>
                                <option value=''>
                                    {getIntlMsg(intl, `app.label.all`)}
                                </option>
                                {sites.map((v, i) => <option value={v} key={i}>
                                    {v && v}
                                </option>)}
                            </Field>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    );
});


const mapStateToProps = ({requestReducer: {item}}, props) => {


    const defaultState = {startDate:moment(new Date()).format("yyyy-MM-DD"),endDate:moment(new Date()).format("yyyy-MM-DD"),site:''}
    return {
        initialValues: {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "searchForm",
    enableReinitialize: true,
})(SearchForm))));

