import * as React from "react";
import {Link} from "react-router-dom";
import PrimaryMenuItem from "./primary";
import AsideFooter from "./footer";
import {getPrimaryMenu} from "../../../constants/options/primaryMenuItems";
import {useContext} from "react";
import SecondaryModule from "./secondary/Module";
import SecondarySecurity from "./secondary/Security";
import SecondaryReport from "./secondary/Report";
import SecondarySetting from "./secondary/Setting";
import SecondaryDashboard from "./secondary/Dashboard";
import {GlobalContext} from "../../index";
import {canDisplay} from "../../../constants/utils/security";
import SecondarySharing from "./secondary/Sharing";


const Aside = props => {

    const {userSession: {roles, name, keycloak}, institution: {acronym}} = useContext(GlobalContext);
    const {} = props

    const secondaries = [
        {displayable: true, component: <SecondaryDashboard key={0}/>},
        {displayable: true, component: <SecondaryModule key={1}/>},
        {displayable: false, component: <SecondarySetting key={2}/>},
        {displayable: false, component: <SecondaryReport key={3}/>},
        {displayable: false, component: <SecondarySecurity key={4}/>},
        {displayable: true, component: <SecondarySharing key={5}/>},
    ]


    return (
        <div className="aside aside-left d-flex aside-fixed" id="kt_aside">
            <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                <div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
                    <Link to={`/`}>
                        <img alt="Logo" src={`/assets/media/logos/zl_ogo_white.png`} className="max-h-40px"/>
                    </Link>
                </div>
                <div
                    className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                    <ul className="nav flex-column" role="tablist">
                        {getPrimaryMenu(acronym).map((item, i) =>
                            canDisplay(roles, item.title) && <PrimaryMenuItem {...item} key={i}/>
                        )}
                    </ul>
                </div>

                <AsideFooter/>

            </div>

            <div className="aside-secondary d-flex flex-row-fluid">
                <div className="aside-workspace scroll scroll-push my-2">
                    <div className="tab-content">
                        {secondaries.map((item, i) => item.component)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Aside)

