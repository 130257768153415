import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "../validator";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import FormItems from "./Items";


const DiagnosticForm = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset, initialValues} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();


    const params = {options: [page]};
    // const {handleLoadItems} = useEntityManager(params, page)
    const {item, loading} = useSelector(state => ({
        loading: state.observationReducer.loadingItem,
        item: state.observationReducer.item,
    }));


    const onFormSubmit = element => {
        dispatch(actions.saveOrUpdateItem({
            options: [page], intl, action: item && item.id ? 'update' : 'save', item,
        }))
    };


    return (
        <div className="tab-pane fade show" id="diagnostic" role="tabpanel" aria-labelledby="diagnostic">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, item}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({observationReducer: {item}}) => {
    const defaultState = {}
    return {
        initialValues:item !== null ? {...item} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "observationForm",
    enableReinitialize: true,
    validate,
})(DiagnosticForm))));

