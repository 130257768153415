import types from "../../actions/types/settings/categoryActionTypes";
import initialState from "../../../constants/utils/initialState";

const categoryReducer = (state = initialState.categoryReducer, action) => {

        switch (action.type) {

            /**
             * LOAD CATEGORIES LIST
             * */
            case types.RESET_CATEGORY:
                return {...state, item: {}};

            /**
             * LOAD CATEGORIES LIST
             * */
            case types.LOAD_CATEGORIES:
                return {...state, loadingItems: action.payload};
            case types.LOAD_CATEGORIES_SUCCEEDED:
                return {...state, items: action.payload, loadingItems: false};
            case types.LOAD_CATEGORIES_FAILED:
                return {...state, loadingItems: action.payload};

            /**
             * LOAD CATEGORIES LIST
             * */
            case types.LOAD_CATEGORIES_NAMES:
                return {...state, loadingLov: action.payload};
            case types.LOAD_CATEGORIES_NAMES_SUCCEEDED:
                return {...state, lovItems: action.payload, loadingLov: false};
            case types.LOAD_CATEGORIES_NAMES_FAILED:
                return {...state, loadingLov: action.payload};

            /**
             * LOAD CATEGORIES LIST
             * */
            case types.LOAD_CATEGORIES_BY_NAME_SHEET:
                return {...state, loadingSheetTypes: action.payload};
            case types.LOAD_CATEGORIES_BY_NAME_SHEET_SUCCEEDED:
                return {...state, sheetTypes: action.payload, loadingSheetTypes: false};
            case types.LOAD_CATEGORIES_BY_NAME_SHEET_FAILED:
                return {...state, loadingSheetTypes: action.payload};


            /**
             * LOAD CATEGORIES LIST
             * */
            case types.LOAD_CATEGORIES_BY_NAME_SPECIMEN:
                return {...state, loadingSpecimenTypes: action.payload};
            case types.LOAD_CATEGORIES_BY_NAME_SPECIMEN_SUCCEEDED:
                return {...state, specimenTypes: action.payload, loadingSpeimenTypes: false};
            case types.LOAD_CATEGORIES_BY_NAME_SPECIMEN_FAILED:
                return {...state, loadingSpecimenTypes: action.payload};


            /**
             * LOAD CUSTOMER CATEGORIES LIST
             * */
            case types.LOAD_CATEGORIES_BY_NAME_CUSTOMER:
                return {...state, loadingCustomerTypes: action.payload};
            case types.LOAD_CATEGORIES_BY_NAME_CUSTOMER_SUCCEEDED:
                return {...state, customerTypes: action.payload, loadingCustomerTypes: false};
            case types.LOAD_CATEGORIES_BY_NAME_CUSTOMER_FAILED:
                return {...state, loadingCustomerTypes: action.payload};


            /**  LOAD CATEGORY BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_CATEGORY:
                return {...state, loadingItem: true};
            case types.LOAD_CATEGORY_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_CATEGORY_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE CATEGORY CASE */
            case types.SAVE_CATEGORY:
                return {...state, savingOrUpdating: true};
            case types.SAVE_CATEGORY_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_CATEGORY_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE CATEGORY CASE  */

            case types.UPDATE_CATEGORY:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_CATEGORY_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating:false
                };
            case types.UPDATE_CATEGORY_FAILED:
                return {...state, savingOrUpdating: false};


            /**  DELETE CATEGORY CASE  */
            case types.DELETE_CATEGORY:
                return {...state, deleting: true,savingOrUpdating:true};
            case types.DELETE_CATEGORY_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_CATEGORY_FAILED:
                return {...state, deleting: false};

            default:
                return state;
        }
    }
;

export default categoryReducer;
