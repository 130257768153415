import React from "react"
import {Field, FieldArray} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import renderContact from "./contact";
import renderTrip from "./trip";

let FormItems = ({item, fields, validate}) => {

    const status = ['yes', 'no', 'unknown']
    const contactWithCovid19DeadStatus = ['yes', 'no', 'unknown']
    const traveledToCovid19CountryStatus = ['yes', 'no']
    const options = [
        'contact_with_traveled_contaminated',
        'visit_covid19_health_center',
        'contact_with_contaminated',
    ]

    const performAddContact = fields => {
        // fields.push()
        console.log({...FieldArray}, '******************')
    }


    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-12 mb-3">
                    <h4><FormattedMessage id={`app.label.contact_14_days`}/></h4>
                </div>
                {options.map((item, i) =>
                    <div className="col-sm-4" key={i}>
                        <Field component={renderRadio} items={status} name={item} label={item} id={item}
                               validate={validate.error}/>
                    </div>
                )}
            </div>

            <div className="row mt-3">
                <div className="col-sm-7">
                    <Field component={renderRadio} items={contactWithCovid19DeadStatus} name="contactWithCovid19Dead"
                           label="contact_with_covid19_dead" id="contact_with_covid19_dead" validate={validate.error}/>
                </div>

                <div className="col-sm-5">
                    <span className="btn btn-sm btn-outline-primary" onClick={() => performAddContact()}>
                        <FormattedMessage id="app.label.add_contact"/>
                    </span>
                </div>

                <div className="col-sm-12">
                    <FieldArray name="covid19Contacts" component={renderContact}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-12 mb-3">
                    <h4><FormattedMessage id={`app.label.travel_history_14_days`}/></h4>
                    <div className="row">
                        <div className="col-sm-7">
                            <Field component={renderRadio} items={traveledToCovid19CountryStatus}
                                   name="traveledToCovid19Countries" label="traveled_to_covid19_countries"
                                   id="traveled_to_covid19_countries" validate={validate.error}/>
                        </div>

                        <div className="col-sm-5">
                            <span className="btn btn-sm btn-outline-primary" onClick={performAddContact}>
                                <FormattedMessage id="app.label.add_trip"/>
                            </span>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <FieldArray name="covid19trips" component={renderTrip}/>
                    </div>

                </div>
            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
