import * as React from "react";
import actions from "../../../../../../../../thunks/common/coreThunks";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch} from "react-redux";
// import {validate} from "./validator";
import FormItemSpecimen from '../../../specimen/Items';
import FormItemResult from '../../create/Items';
import {validate} from '../../../specimen/validator';
import FormFooter from "../../../../../../common/form/FormFooter";
import {DiagnosticMapper,} from "../../../../../../../../constants/models/diagnostic";
import {getUpdatedDiagnostic, ResultMapper, UpdateResult} from "../../../../../../../../constants/models/result";
import {renderField} from "../../../../../../../common/utils/FormComponent";


const UpdateResultForm = injectIntl((props) => {

    const {intl, loading, item, handleSubmit, pristine, valid, submitting, reset, hideModal, status} = props;
    const dispatch = useDispatch();
    const params = {intl, options: ['requests']}



    const onFormSubmit = element => {


        if (item) {
            dispatch(actions.saveOrUpdateItem({
                options: ['requests', 'update-diagnostic'],
                intl,
                action: item && item.id ? 'update' : 'save',
                item: getUpdatedDiagnostic(element, item)
            }))
            //
            // dispatch(actions.resetItem(params))
            // dispatch(reset())
        }
    };

    const ResetForm = () => {
        reset('updateResultForm');
        dispatch(actions.resetItem(params))
        hideModal('updateResult')
    }

    if (status === 400) ResetForm()

    return (
        <div className="row">
            <div className="col-sm-12">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <FormItemSpecimen {...{item, validate}}/>
                    <FormItemResult {...{item, validate}}/>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <Field className="form-control form-control-sm" id="pcr_result_date"
                                   name="remark"
                                   component={renderField} type="text"
                                   label={`remark`}
                                   placeholder="remark" validate={validate.error}/>
                        </div>
                    </div>
                    <FormFooter {...{loading, item, pristine, valid, submitting, reset}}/>
                </form>
            </div>
        </div>
    );
});


const mapStateToProps = ({requestReducer: {item}}, props) => {

    const initVal = {...new DiagnosticMapper(item), ...new DiagnosticMapper(item).analyses, ...new ResultMapper(item)};


    const defaultState = {}
    return {
        initialValues: item !== null ? {...initVal} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "updateResultForm",
    enableReinitialize: true,
    validate,
})(UpdateResultForm))));

