import React, {useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import {IntlProvider} from "react-intl";
import {ConnectedRouter} from "connected-react-router";
import {Switch} from "react-router-dom";
import messages from "../constants/messages";
import routes from '../constants/routes'
import PrivateRoute from "./common/routes/PrivateRoute";
import GlobalHomePage from "./pages/modules/obsheet/dashboard";
import HeaderMobile from "./layout/mobile";
import Aside from "./layout/aside";
import QuickModuleAction from "./layout/quickactions/module";
import QuickUserAction from "./layout/quickactions/user";
import QuickNotificationAction from "./layout/quickactions/notification";
import ChatBtn from "./layout/quickactions/chat/ChatBtn";
import QuickChatAction from "./layout/quickactions/chat";
import {useSelector} from "react-redux";
import SpecimenModal from "./pages/modules/request/forms/specimen";
import CreateResultForm from "./pages/modules/request/forms/result/create";
import UpdateResultModal from "./pages/modules/request/forms/result/update";
import TestIndicatorUploaderModal from "./pages/settings/refeTabs/indicator/modal/test";


export const GlobalContext = React.createContext({});
const Layout = ({history}) => {


    const keycloak = useSelector(state => state.keycloak);
    const roles = keycloak?.tokenParsed?.resource_access?.rtr.roles;
    const {name, email} = keycloak?.tokenParsed;


    const [modalIsOpen,setIsOpen] = React.useState({});

    const userSession = {keycloak, roles, name, email}

    const showModal=(name)=>{
        // alert(name)
        setIsOpen({open:true,name:name})
    }

    const hideModal=(name)=>{
        setIsOpen({open:false,name:name})
    }


    const institution = {acronym: "zl", description: "Zanmi Lasante /PIH"}
    const gblParams = {institution, userSession};

    return institution ? (
        <GlobalContext.Provider value={{...gblParams, history,modalIsOpen,showModal,hideModal}}>
            <IntlProvider locale={'fr'} messages={messages['fr']}>
                <ConnectedRouter history={history}>
                    <HeaderMobile/>
                    <div className="d-flex flex-column flex-root">
                        <div className="d-flex flex-row flex-column-fluid page">
                            <Aside/>
                            <Switch>
                                <PrivateRoute exact path={`/`} component={GlobalHomePage}/>
                                {routes.map((route, index) =>
                                    <PrivateRoute key={index} path={route.path} exact={route.exact}
                                                  component={route.main} roles={[['RealmAdmin']]}/>
                                )}
                            </Switch>
                        </div>
                        
                        <QuickModuleAction/>
                        <QuickUserAction/>
                        <QuickNotificationAction/>
                        <QuickChatAction/>
                        <ChatBtn/>
                        </div>

                    <SpecimenModal/>
                    <CreateResultForm/>
                    <UpdateResultModal/>
                    <TestIndicatorUploaderModal/>
                </ConnectedRouter>

            </IntlProvider>
        </GlobalContext.Provider>
    ) : <div/>
}


export default React.memo(Layout);