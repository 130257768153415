import * as validator from "../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.covidContactId = validator.validateString('full_name', values.full_name, 3, 150, true);
    errors.full_name = validator.validateString('covid_contact', values.first_name, 3, 150, true);
    return errors
};


