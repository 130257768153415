import types from "../../actions/types/request/actionTypes";
import initialState from "../../../constants/utils/initialState";

const reducer = (state = initialState.requestReducer, action) => {

        switch (action.type) {

            /**
             * LOAD REQUEST LIST
             * */
            case types.RESET_CART:
            case types.RESET_REQUEST:
            case types.RESET_RESULT:
            case types.RESET_RECEPTION:
            case types.RESET_SPECIMEN:
            case types.RESET_LABORATORY:
            case types.RESET_NOTIFICATION:
            case types.RESET_ARCHIVED:
            case types.RESET_UNFINISHED:
                return {...state, item: null, loadingItems: false,status:null};

            /**
             * LOAD REQUEST LIST
             * */
            case types.LOAD_REQUESTS:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION:
            case types.LOAD_REQUESTS_STATUS_CART:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS:
            case types.LOAD_REQUESTS_STATUS_LABORATORY:
            case types.LOAD_REQUESTS_STATUS_RESULTS:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED:
                return {...state, loadingItems: action.payload, items:[]};


            case types.LOAD_REQUESTS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_CART_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_LABORATORY_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_RESULTS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED_SUCCEEDED:
                return {...state, item: null, items: action.payload, loadingItems: false};

            case types.LOAD_REQUESTS_FAILED:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED_FAILED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION_FAILED:
            case types.LOAD_REQUESTS_STATUS_CART_FAILED:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS_FAILED:
            case types.LOAD_REQUESTS_STATUS_LABORATORY_FAILED:
            case types.LOAD_REQUESTS_STATUS_RESULTS_FAILED:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION_FAILED:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED_FAILED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED_FAILED:
                return {...state, loadingItems: action.payload};


            /**  LOAD REQUEST BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_REQUEST:
                return {...state, loadingItem: true};
            case types.LOAD_REQUEST_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_REQUEST_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE REQUEST CASE */
            case types.SAVE_REQUEST:
                return {...state, savingOrUpdating: true, status:null};
            case types.SAVE_REQUEST_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    status:400,
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_REQUEST_FAILED:
                return {...state, savingOrUpdating: false,status:null};



            /**  UPDATE REQUEST CASE  */

            case types.UPDATE_REQUEST:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_REQUEST_SUCCEEDED:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating: false,

                };
            case types.UPDATE_REQUEST_FAILED:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC_FAILED:
                return {...state, savingOrUpdating: false};



            /**  ADD SPECIMEN REQUEST CASE  */

            case types.ADD_DIAGNOSTIC:
                return {...state, savingOrUpdating: true,status:null};
            case types.ADD_DIAGNOSTIC_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    savingOrUpdating: true,
                    status:400,
                };
            case types.ADD_DIAGNOSTIC_FAILED:
                return {...state, savingOrUpdating: false,status:null};



            /**  ADD SPECIMEN REQUEST CASE  */

            case types.ADD_RESULT:
                return {...state, savingOrUpdating: true, status:null,};
            case types.ADD_RESULT_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating: false,
                    status:400,
                };
            case types.ADD_RESULT_FAILED:
                return {...state, savingOrUpdating: false, status:null};



            /**  DELETE REQUEST CASE  */
            case types.DELETE_REQUEST:
                return {...state, deleting: true};
            case types.DELETE_REQUEST_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_REQUEST_FAILED:
                return {...state, deleting: false};


            /**  CHANGE REQUEST STATUS CASE  */
            case types.CHANGE_REQUEST_CHANGE_STATUS:
                return {...state, deleting: true};
            case types.CHANGE_REQUEST_CHANGE_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {
                        ...state,
                        items: state.items.filter(item => item.id !== action.payload.item.id),
                        item: null,
                        deleting: false
                    }
                    : state;
            case types.CHANGE_REQUEST_CHANGE_FAILED:
                return {...state, deleting: false};


            default:
                return state;
        }
    }
    ;

export default reducer;
