const actionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    RESET_REQUEST: "RESET_REQUEST",
    RESET_RECEPTION: "RESET_RECEPTION",
    RESET_CART: "RESET_CART",
    RESET_SPECIMEN: "RESET_SPECIMEN",
    RESET_LABORATORY: "RESET_LABORATORY",
    RESET_RESULT: "RESET_RESULT",
    RESET_NOTIFICATION: "RESET_NOTIFICATION",
    RESET_ARCHIVED: "RESET_ARCHIVED",
    RESET_UNFINISHED: "RESET_UNFINISHED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS: "LOAD_REQUESTS",
    LOAD_REQUESTS_SUCCEEDED: "LOAD_REQUESTS_SUCCEEDED",
    LOAD_REQUESTS_FAILED: "LOAD_REQUESTS_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_RECEPTION: "LOAD_REQUESTS_STATUS_RECEPTION",
    LOAD_REQUESTS_STATUS_RECEPTION_SUCCEEDED: "LOAD_REQUESTS_STATUS_RECEPTION_SUCCEEDED",
    LOAD_REQUESTS_STATUS_RECEPTION_FAILED: "LOAD_REQUESTS_STATUS_RECEPTION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_CONFIRMED: "LOAD_REQUESTS_STATUS_CONFIRMED",
    LOAD_REQUESTS_STATUS_CONFIRMED_SUCCEEDED: "LOAD_REQUESTS_STATUS_CONFIRMED_SUCCEEDED",
    LOAD_REQUESTS_STATUS_CONFIRMED_FAILED: "LOAD_REQUESTS_STATUS_CONFIRMED_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_ARCHIVED: "LOAD_REQUESTS_STATUS_ARCHIVED",
    LOAD_REQUESTS_STATUS_ARCHIVED_SUCCEEDED: "LOAD_REQUESTS_STATUS_ARCHIVED_SUCCEEDED",
    LOAD_REQUESTS_STATUS_ARCHIVED_FAILED: "LOAD_REQUESTS_STATUS_ARCHIVED_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_CART: "LOAD_REQUESTS_STATUS_CART",
    LOAD_REQUESTS_STATUS_CART_SUCCEEDED: "LOAD_REQUESTS_STATUS_CART_SUCCEEDED",
    LOAD_REQUESTS_STATUS_CART_FAILED: "LOAD_REQUESTS_STATUS_CART_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_NOTIFICATION: "LOAD_REQUESTS_STATUS_NOTIFICATION",
    LOAD_REQUESTS_STATUS_NOTIFICATION_SUCCEEDED: "LOAD_REQUESTS_STATUS_NOTIFICATION_SUCCEEDED",
    LOAD_REQUESTS_STATUS_NOTIFICATION_FAILED: "LOAD_REQUESTS_STATUS_NOTIFICATION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_SPECIMENS: "LOAD_REQUESTS_STATUS_SPECIMENS",
    LOAD_REQUESTS_STATUS_SPECIMENS_SUCCEEDED: "LOAD_REQUESTS_STATUS_SPECIMENS_SUCCEEDED",
    LOAD_REQUESTS_STATUS_SPECIMENS_FAILED: "LOAD_REQUESTS_STATUS_SPECIMENS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_RESULTS: "LOAD_REQUESTS_STATUS_RESULTS",
    LOAD_REQUESTS_STATUS_RESULTS_SUCCEEDED: "LOAD_REQUESTS_STATUS_RESULTS_SUCCEEDED",
    LOAD_REQUESTS_STATUS_RESULTS_FAILED: "LOAD_REQUESTS_STATUS_RESULTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_LABORATORY: "LOAD_REQUESTS_STATUS_LABORATORY",
    LOAD_REQUESTS_STATUS_LABORATORY_SUCCEEDED: "LOAD_REQUESTS_STATUS_LABORATORY_SUCCEEDED",
    LOAD_REQUESTS_STATUS_LABORATORY_FAILED: "LOAD_REQUESTS_STATUS_LABORATORY_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_STATUS_UNFINISHED: "LOAD_REQUESTS_STATUS_UNFINISHED",
    LOAD_REQUESTS_STATUS_UNFINISHED_SUCCEEDED: "LOAD_REQUESTS_STATUS_UNFINISHED_SUCCEEDED",
    LOAD_REQUESTS_STATUS_UNFINISHED_FAILED: "LOAD_REQUESTS_STATUS_UNFINISHED_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PENDING_REQUESTS: "LOAD_PENDING_REQUESTS",
    LOAD_PENDING_REQUESTS_SUCCEEDED: "LOAD_PENDING_REQUESTS_SUCCEEDED",
    LOAD_PENDING_REQUESTS_FAILED: "LOAD_PENDING_REQUESTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMEN_DONE_REQUESTS: "LOAD_SPECIMEN_DONE_REQUESTS",
    LOAD_SPECIMEN_DONE_REQUESTS_SUCCEEDED: "LOAD_SPECIMEN_DONE_REQUESTS_SUCCEEDED",
    LOAD_SPECIMEN_DONE_REQUESTS_FAILED: "LOAD_SPECIMEN_DONE_REQUESTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_DONE_REQUESTS: "LOAD_DONE_REQUESTS",
    LOAD_DONE_REQUESTS_SUCCEEDED: "LOAD_DONE_REQUESTS_SUCCEEDED",
    LOAD_DONE_REQUESTS_FAILED: "LOAD_DONE_REQUESTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUEST: "LOAD_REQUEST",
    LOAD_REQUEST_SUCCEEDED: "LOAD_REQUEST_SUCCEEDED",
    LOAD_REQUEST_FAILED: "LOAD_REQUEST_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_REQUEST: "SAVE_REQUEST",
    SAVE_REQUEST_SUCCEEDED: "SAVE_REQUEST_SUCCEEDED",
    SAVE_REQUEST_FAILED: "SAVE_REQUEST_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_REQUEST: "UPDATE_REQUEST",
    UPDATE_REQUEST_SUCCEEDED: "UPDATE_REQUEST_SUCCEEDED",
    UPDATE_REQUEST_FAILED: "UPDATE_REQUEST_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_REQUEST_UPDATE_DIAGNOSTIC: "UPDATE_REQUEST_UPDATE_DIAGNOSTIC",
    UPDATE_REQUEST_UPDATE_DIAGNOSTIC_SUCCEEDED: "UPDATE_REQUEST_UPDATE_DIAGNOSTIC_SUCCEEDED",
    UPDATE_REQUEST_UPDATE_DIAGNOSTIC_FAILED: "UPDATE_REQUEST_UPDATE_DIAGNOSTIC_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    CHANGE_REQUEST_CHANGE_STATUS: "CHANGE_REQUEST_CHANGE_STATUS",
    CHANGE_REQUEST_CHANGE_SUCCEEDED: "CHANGE_REQUEST_CHANGE_SUCCEEDED",
    CHANGE_REQUEST_CHANGE_FAILED: "CHANGE_REQUEST_CHANGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_REQUEST: "DELETE_REQUEST",
    DELETE_REQUEST_SUCCEEDED: "DELETE_REQUEST_SUCCEEDED",
    DELETE_REQUEST_FAILED: "DELETE_REQUEST_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * ADD_DIAGNOSTIC REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    ADD_DIAGNOSTIC: "ADD_DIAGNOSTIC",
    ADD_DIAGNOSTIC_SUCCEEDED: "ADD_DIAGNOSTIC_SUCCEEDED",
    ADD_DIAGNOSTIC_FAILED: "ADD_DIAGNOSTIC_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * ADD_RESULT REQUEST ACTION TYPES
     * -------------------------------------------
     * */
    ADD_RESULT: "ADD_RESULT",
    ADD_RESULT_SUCCEEDED: "ADD_RESULT_SUCCEEDED",
    ADD_RESULT_FAILED: "ADD_RESULT_FAILED",



};


export default actionTypes;
