import * as React from "react";
import {useContext, useRef} from "react";
import {useParams, withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {getResultValues} from "../../../../../constants/options/subheaderValues";
import AsyncInputSearch from "../../../../common/utils/AsyncInputSearch";
import {canDisplay} from "../../../../../constants/utils/security";
import {GlobalContext} from "../../../../index";
import actions from "../../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";
import {useReactToPrint} from "react-to-print";
import AllResultSheet from "../reports/AllResultSheet";


const ResultSubHeader = injectIntl(props => {

    const {intl, items} = props
    const resultFormRef = useRef();
    const {btnTitle} = getResultValues;
    const {userSession: {roles}, institution: {acronym}} = useContext(GlobalContext);
    const {module, page} = useParams();
    const dispatch = useDispatch()

    const options = {options: [module, 'status', page]};


    const handleStateChange = status => {
        const options = {options: [module, 'archive-all'], intl, status}
        dispatch(actions.changeStatus(options))
    }

    const handlePrintResult = useReactToPrint({
        content: () => resultFormRef.current,
    });


    return (
        <div className="subheader py-6 py-lg-8 subheader-transparent" id="kt_subheader">
            <div
                className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

                <div className="d-flex align-items-center flex-wrap mr-2">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        <FormattedMessage id={`app.label.${page}_list`}/>
                    </h5>
                    <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-300"/>
                    <div className="d-flex align-items-center" id="kt_subheader_search">
                        <span className="text-dark-50 font-weight-bold" id="kt_subheader_total">
                            {items.length || 0}{" "}
                            <FormattedMessage id={`app.label.total`}/>
                        </span>
                        <form className="ml-5">
                            <div className="input-group input-group-sm bg-white border-0 rounded min-w-175px">
                                {<AsyncInputSearch {...{options}}/>}
                            </div>
                        </form>
                    </div>
                </div>

                {items.length > 0 ?

                    <div className="d-flex align-items-center flex-wrap">
                        {canDisplay(roles, btnTitle) &&
                        <div className="dropdown">
                            <button className="btn btn-outline-primary dropdown-toggle" type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FormattedMessage id={`app.label.result_menu_options`}
                                                  defaultMessage={`Result Menu Option`}/>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{}}>
                            <span className="dropdown-item cursor-pointer" onClick={() => handleStateChange(page)}>
                                <span className="flaticon2-delivery-package text-primary mr-3"/>
                                <FormattedMessage id={`app.label.archive_all_results`}
                                                  defaultMessage={`Archive All Results`}/>
                            </span>
                                <span className="dropdown-item cursor-pointer" onClick={handlePrintResult}>
                                <span className="flaticon2-printer text-primary mr-3"/>
                                <FormattedMessage id={`app.label.print_all_results`}
                                                  defaultMessage={`Print all Results`}/>
                            </span>
                            </div>
                        </div>
                        }
                    </div> : null}
            </div>
            <div className="print-container" style={{margin: "0", padding: "0", display: "none"}}>
                <AllResultSheet ref={resultFormRef} {...{items}}/>
            </div>
        </div>
    )
})

export default React.memo(withRouter(ResultSubHeader))

