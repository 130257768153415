import * as React from "react";
import {FormattedDate, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {getInfoBarBases} from "../../../../../../../constants/options/infobarItems";
import Item from "./Item";
import moment from 'moment';


const InfoBar = injectIntl((props) => {

    const {item} = props;
    const {payment, symptoms, destination, departure} = item;
    const {testType:{name}} = item;
    const {trxCode, amount, paymentType} = payment


    // const formatDeparture = <FormattedDate value={departure} day="numeric" month="short" year="numeric"/>
    const formatDeparture = moment(departure).format('DD/MM/YYYY')

    const barBases = getInfoBarBases();
    const infos = [trxCode, amount, paymentType, formatDeparture, destination, name];
    const colored = ['primary', 'info', 'primary', 'success', 'primary', 'info'];
    let barItems = []

    for (let i = 0; i < infos.length; i++) {
        const value = infos[i];
        const base = barBases[i];
        const color = colored[i];
        barItems.push({...base, value, color})
    }


    const isNegative = !item?.diagnostic ? 'bg-secondary-o-45'
        : !item?.diagnostic?.result ? 'bg-info-0-45'
            : item?.diagnostic?.result?.status === 'NEGATIVE' ? 'bg-success-o-45'
                : item?.diagnostic?.result?.status === 'POSITIVE' ? 'bg-danger-o-45' : ''

    return (
        <div className={`d-flex align-items-center flex-wrap  p-3 ${isNegative}`}>
            {barItems && barItems.map((item, i) => <Item {...item} key={i}/>)}
        </div>
    )


})


export default React.memo(withRouter(InfoBar))
